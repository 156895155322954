<template>
  <!-- 简历 -->
  <div id="myResume">
    <el-form :model="form" ref="ruleForm" label-width="150px" :rules="rules">
      <el-row>
        <el-col :span="12">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="form.name" placeholder="请填写姓名"></el-input>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="邮箱" prop="email">
            <el-input v-model="form.email" placeholder="请填写邮箱"></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="所学专业" prop="specialty">
        <el-select
          v-model="form.specialty"
          placeholder="请选择所学专业"
          @change="$forceUpdate()"
        >
          <el-option label="CS" :value="0"></el-option>
          <el-option label="MIS" :value="1"></el-option>
          <el-option label="EE" :value="2"></el-option>
          <el-option label="ME" :value="3"></el-option>
          <el-option label="BME" :value="4"></el-option>
          <el-option label="ECON" :value="5"></el-option>
          <el-option label="MFE" :value="6"></el-option>
          <el-option label="ACCOUNTING" :value="7"></el-option>
          <el-option label="其它" :value="8"></el-option>
        </el-select>
      </el-form-item>

      <el-form-item label="毕业院校" prop="school">
        <div
          class="relativeInput"
          v-for="(school, index) in form.school"
          :key="index"
        >
          <el-autocomplete
            v-model="form.school[index].name"
            :fetch-suggestions="querySchoolSearch"
            :trigger-on-focus="false"
            placeholder="请选择学校"
            :popper-append-to-body="false"
            @input="
              (val) => {
                searchSchoolLodash(val);
              }
            "
            @select="(option) => selectSchool(option, index)"
            style="width: calc(100% - 162px)"
          >
            <template slot-scope="{ item }">
              {{ item.name }}
            </template>
          </el-autocomplete>
          <el-select
            style="width: 150px; margin-left: 12px"
            v-model="form.school[index].qualification"
          >
            <el-option label="本科" :value="0"></el-option>
            <el-option label="硕士" :value="1"></el-option>
            <el-option label="博士" :value="2"></el-option>
          </el-select>
          <i
            v-if="index == 0"
            class="el-icon-circle-plus-outline absoluteIcon"
            @click="addGraduate"
          ></i>
          <i
            v-else
            class="el-icon-remove-outline absoluteIcon"
            @click="deleteGraduate(index)"
          ></i>
        </div>
      </el-form-item>

      <el-form-item label="曾经公司" prop="originalCompany">
        <div
          class="relativeInput"
          v-for="(company, index) in form.company"
          :key="index"
        >
          <el-autocomplete
            v-model="form.company[index]"
            :fetch-suggestions="querySearch"
            :trigger-on-focus="false"
            placeholder="输入公司名"
            :popper-append-to-body="false"
            @input="searchLodash"
            @select="(val) => (form.company[index] = val.name)"
            style="width: 100%"
          >
            <template slot-scope="{ item }" v-if="companys.length > 0">
              {{ item.name }}
            </template>
          </el-autocomplete>
          <i
            v-if="index == 0"
            class="el-icon-circle-plus-outline absoluteIcon"
            @click="addCompany"
          ></i>
          <i
            v-else
            class="el-icon-remove-outline absoluteIcon"
            @click="deleteCompany(index)"
          ></i>
        </div>
      </el-form-item>
      <el-form-item label="项目经验" prop="project">
        <div
          v-for="(project, index) in form.project"
          class="relativeInput"
          :key="index"
        >
          <el-input
            v-model="form.project[index].title"
            placeholder="请输入标题"
            style="margin-bottom: 6px"
          ></el-input>
          <el-input
            type="textarea"
            v-model="form.project[index].content"
            placeholder="请输入内容"
          ></el-input>
          <i
            v-if="index == 0"
            class="el-icon-circle-plus-outline absoluteIcon"
            @click="addProject"
          ></i>
          <i
            v-else
            class="el-icon-remove-outline absoluteIcon"
            @click="deleteProject(index)"
          ></i>
        </div>
      </el-form-item>

      <el-row>
        <el-col :span="10">
          <el-form-item label="工作经验" prop="workExperience">
            <el-select
              v-model="form.workExperience"
              placeholder="工作经验"
              @change="$forceUpdate()"
            >
              <el-option label="fresh grad 无实习或全职" :value="0"></el-option>
              <el-option label="3个月实习或全职" :value="1"></el-option>
              <el-option label="3个月-1年" :value="2"></el-option>
              <el-option label="1-3年" :value="3"></el-option>
              <el-option label="3-5年" :value="4"></el-option>
              <el-option label="5-10年" :value="5"></el-option>
              <el-option label="11-15年" :value="6"></el-option>
              <el-option label="15年以上" :value="7"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="14">
          <el-form-item label="刷题量" prop="questions">
            <el-input
              v-model="form.questions"
              type="number"
              placeholder="请填写刷题量"
              style="width: 150px"
            ></el-input>
            <el-upload
              ref="upload"
              :on-success="handleLCSuccess"
              :limit="3"
              :on-remove="removeLCFile"
              :action="uploadUrl"
              class="upload-demo"
              accept=".jpg,.jpeg,.png,.gif,.bmp,.JPG,.JPEG,.PBG,.GIF,.BMP"
              style="display: inline-block; margin-left: 12px"
              :data="{ isPublic: false }"
              :headers="{ 'X-Access-Token': token }"
              :before-upload="beforeUpload"
              :show-file-list="false"
            >
              <el-button icon="el-icon-paperclip" slot="trigger"
                >上传截图
              </el-button>
            </el-upload>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="简历" prop="resume">
        <el-upload
          ref="upload"
          :on-success="handlePDFSuccess"
          :limit="1"
          :before-upload="beforeUpload"
          :on-remove="removePDFFile"
          :action="uploadUrl"
          class="upload-demo"
          accept=".pdf,.PDF"
          style="display: inline-block; width: 100%"
          :data="{ isPublic: false }"
          :headers="{ 'X-Access-Token': token }"
        >
          <el-button icon="el-icon-paperclip">选择简历 </el-button>
          <div class="el-upload__tip" slot="tip">
            支持pdf格式文件，文件大小不超过10M
          </div>
        </el-upload>
      </el-form-item>
      <el-row>
        <el-col :span="12">
          <el-form-item label="面试岗位" prop="post">
            <el-select
              v-model="form.post"
              placeholder="请选择岗位"
              style="margin-bottom: 0"
              @change="$forceUpdate()"
            >
              <el-option label="码农SDE" :value="0"></el-option>
              <el-option label="前端FrontEndEng" :value="1"></el-option>
              <el-option label="移动端MobileEng" :value="2"></el-option>
              <el-option
                label="机器学习MachineLearningEng"
                :value="3"
              ></el-option>
              <el-option label="数据科学DataScience" :value="4"></el-option>
              <el-option label="产品PM" :value="5"></el-option>
              <el-option label="管理Manager" :value="6"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <el-form-item label="求职目标" prop="target">
            <el-select v-model="form.target">
              <el-option label="New Grad" :value="0"></el-option>
              <el-option label="Internship" :value="1"></el-option>
              <el-option label="合同工" :value="2"></el-option>
              <el-option label="跳槽" :value="3"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="第三人称简介" prop="briefIntroduction">
        <el-input
          v-model="form.briefIntroduction"
          type="textarea"
          placeholder="第三人称个人简介，内推时需要用到"
          @input="$forceUpdate()"
        ></el-input>
      </el-form-item>
      <el-form-item label="自我介绍" prop="selfIntroduction">
        <el-input
          v-model="form.selfIntroduction"
          type="textarea"
          placeholder="增加内推概率，让内推人对你更了解"
          @input="$forceUpdate()"
        ></el-input>
      </el-form-item>
    </el-form>
    <div style="text-align: center">
      <el-button type="primary" @click="saveCv">保存信息</el-button>
    </div>
  </div>
</template>
<script>
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
import { mapState } from "vuex";
import { searchCompanys, getSchools } from "@/service/company";
import { getCv, saveCv } from "@/service/userCenter";
import _ from "lodash";
export default {
  name: "interviewPlan",
  components: {
    ElImageViewer,
  },
  data() {
    const validateLCcode = (rule, value, callback) => {
      if (!this.form.questions || this.form.questions == "") {
        callback(new Error("请填写刷题量"));
      } else {
        if (!this.form.lcPrintScreen || this.form.lcPrintScreen == "") {
          callback(new Error("请上传LeetCode刷题截图"));
        } else {
          callback();
        }
      }
    };
    return {
      form: {
        school: [
          {
            name: "",
            qualification: "",
          },
        ],
        company: [""],
        project: [{ title: "", content: "" }],
      },
      token: window.localStorage.getItem("CSON_PAGE_TOKEN"),
      uploadUrl: process.env.VUE_APP_BASE_URL + "/common/upload",
      schools: [],
      rules: {
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        email: [
          {
            required: true,
            message: "请输入邮箱",
            trigger: "blur",
          },
        ],
        specialty: [
          {
            required: true,
            message: "请选择所学专业",
            trigger: "change",
          },
        ],
        school: [
          {
            required: true,
          },
        ],
        workExperience: [
          {
            required: true,
            message: "请选择工作经验",
            trigger: "change",
          },
        ],
        questions: [
          {
            required: true,
            validator: validateLCcode,
            trigger: "change",
          },
        ],
        resume: [
          {
            required: true,
            message: "请上传简历",
            trigger: "change",
          },
        ],
        post: [
          {
            required: true,
            message: "请选择面试岗位",
            trigger: "change",
          },
        ],
        target: [
          {
            required: true,
            message: "请选择求职目标",
            trigger: "change",
          },
        ],
      },
    };
  },
  methods: {
    getInterview() {
      if (!this.userInfo || !this.userInfo.id) {
        this.$router.push("/registerform");
        return;
      }
      this.getInternalRecommendation();
    },
    handlePDFSuccess(res, file) {
      this.form.resume = res.result.objectKey;
    },
    removePDFFile() {
      this.form.resume = "";
    },
    handleLCSuccess(res) {
      this.$message.success("上传成功！");
      this.form.lcPrintScreen = res.result.objectKey;
    },
    removeLCFile() {
      this.form.lcPrintScreen = "";
    },
    closeViewer() {
      this.showViewer = false;
    },
    querySearch(queryString, cb, type) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.companys);
      }, 1000);
    },
    searchLodash: _.debounce(function (val) {
      this.selectLoading = true;
      this.searchCompanys(val);
    }, 1000),
    searchCompanys(val) {
      searchCompanys(val).then((res) => {
        if (res.success) {
          this.selectLoading = false;
          this.companys = res.result;
        }
      });
    },
    beforeUpload(file) {
      const isLt10M = file.size / 1024 / 1024 < 10;
      if (!isLt10M) {
        this.$message.error("上传文件大小不能超过 10MB!");
      }
      return isLt10M;
    },
    querySchoolSearch(queryString, cb) {
      clearTimeout(this.timeout);
      this.timeout = setTimeout(() => {
        cb(queryString == "" ? [] : this.schools);
      }, 1000);
    },
    searchSchools(val) {
      getSchools(val).then((res) => {
        if (res.success) {
          this.schools = res.result;
        }
      });
    },
    selectSchool(option, index) {
      this.form.school[index].name = option.name;
    },
    searchSchoolLodash: _.debounce(function (val) {
      this.searchSchools(val);
    }, 1000),

    addGraduate() {
      this.form.school.push({ name: "", qualification: "" });
    },
    deleteGraduate(index) {
      this.form.school.splice(index, 1);
    },
    addCompany() {
      this.form.company.push("");
    },
    deleteCompany(index) {
      this.form.company.splice(index, 1);
    },
    addProject() {
      this.form.project.push({
        title: "",
        content: "",
      });
    },
    deleteProject(index) {
      this.form.project.splice(index, 1);
    },
    getCv() {
      getCv().then((res) => {
        if (res.success) {
          this.form = res.result;
          this.form.specialty = res.result.specialty
            ? res.result.specialty.value
            : "";
          this.form.workExperience = res.result.workExperience
            ? res.result.workExperience.value
            : "";
          this.form.post = res.result.post ? res.result.post.value : "";
          this.form.target = res.result.target ? res.result.target.value : "";
        }
      });
    },
    saveCv() {
      this.$refs.ruleForm.validate((valid) => {
        if (valid) {
          saveCv(this.form).then((res) => {
            if (res.success) {
              this.$message.success("保存成功！");
            }
          });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {
    this.getCv();
  },
  computed: {
    ...mapState(["userInfo"]),
  },
  watch: {
    form: {
      handler(val) {
        this.$forceUpdate();
      },
      deep: true,
    },
    "form.basics": {
      handler(val) {
        this.$forceUpdate();
      },
    },
  },
};
</script>
<style scoped>
#myResume {
  padding: 30px 100px;
}
.referDialog {
  z-index: 99 !important;
}
::v-deep .el-dialog__wrapper {
  z-index: 98 !important;
}
::v-deep .el-dialog__body {
  padding: 12px 20px;
}
.el-upload__tip {
  display: inline;
  margin-left: 12px;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.relativeInput {
  position: relative;
  margin-bottom: 6px;
}
.absoluteIcon {
  position: absolute;
  right: -30px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  cursor: pointer;
}
</style>